@import '../../css/variable.scss';
.header{
    background: $black;
    padding:20px;
}
// scoial icons
.social-icons{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    list-style: none !important;
}
.social-icons li{
    border-radius: 50%;
    vertical-align: middle;
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
    background: $primary;
}
.social-icons li img{
    width:26px;
    height: 26px;
}
.logo{
    text-align: center;
    padding-top:30px;
}
.logo img{
    width: 180px;
}
.contact-info p{
    color:$yellow-2;
    font-size: $h6;
    margin-bottom: 0px;
    text-align: end;
}
.contact-info p img{
    width: 18px;
    margin-right: 5px;
}
.small-margin{
    margin-bottom: 10px;
}