@import './variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,400;1,500&family=Playfair+Display:ital,wght@0,400;0,600;1,400;1,600&family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@font-face {
    font-family: "Monotype Corsiva";
    src: url('./fonts/MonotypeCorsiva.ttf') format('truetype');
  }
body{
    font-family: 'Montserrat', sans-serif;
}
h1{
    font-family: 'Playfair Display', serif;
}
h2{
    font-family: 'Poppins', sans-serif;
}
h3,h4{
font-family: "Monotype Corsiva";
}
.self-end{
    align-self: flex-end;
    align-self: end;
}
.self-center{
    align-self: center;
}
.btn-primary{
    background: $white !important;
    font-size: $h5 !important;
    border: 1px solid $grey !important;
    border-radius: 25px !important;
    color: $black !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
    line-height: inherit !important;
padding-bottom: 8px !important;
}
.relative-position{
    position: relative;
    z-index: 999;
}
.padding-right-none{
    padding-right: 0px !important;
}
.item{
    cursor: pointer;
}
.cursor-pointer{
    cursor: pointer;
}
@media (min-width:992px) {
    .desktop-menu{
        display: block;
    }
    .mobile-menu{
        display: none;
    }
    .mobile-footer{
        display: none;
    }
    .desktop-footer{
        display: block;
    }
    .static-whatsapp{
        position: fixed;
        right: 20px;
        top: 40%;
        z-index: 999;
    }
    .static-whatsapp .whatsapp-icon{
        cursor: pointer;
    }
    .static-whatsapp .whatsapp-icon img{
        width: 62px;
    }
}
@media (max-width:992px) {
    .desktop-menu{
        display: none;
    }
    .static-whatsapp{
        display: none;
    }
    .mobile-menu{
        display: block;
    }  
    html,body{
        overflow-x: hidden;
    }
    .certified-block {
        padding-bottom: 30px !important;
    }
    .testimonials-title-block img {
        display: none;
    }
    .line-shap img {
        width: 70% !important;
    } 
    .logo img {
        width: 100% !important;
      }
    .btn-primary{
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
    .mobile-footer{
        display: block;
    }
    .desktop-footer{
        display: none;
    }
}
.invisible-feedback{
    width: auto;
    margin-top: 0.25rem;
    font-size: .875rem;
    color: #f93154;
}
.is-invalid{
    border-color: #f93154 !important;
    background-image: none !important;
}
.form-label sup{
    color: red;
font-size: 18px;
}