@import '../css/variable.scss';
.products{
    padding-top:60px;
    padding-bottom: 60px;
}
.text-captilize{
    text-transform: capitalize;
}
.product-image img{
    width: 100%;
}
.product-block{
    position: relative;
    margin-top: 30px;
    box-shadow: -4px 10px 10px rgba(0, 0, 0, 0.14);
}
.product-block .product-title{
    font-size: $size24;
    background: #696969e8;
    backdrop-filter: blur(2px);
    padding: 20px;
    position: absolute;
z-index: 9999;
bottom: -10px;
left: 0px;
right: 0px;
color: #fff;
text-transform: uppercase;
text-align: center;
}
.product-details-content p{
    font-size: $size24;
    color: $grey-5;
    margin-top:30px;
}
.product-details-content{
    padding-left: 40px;
}
.p-dicon{
    padding-top:30px;
}
.p-dicon img{
    width: 82px;
}
.enquiry-form{
    background: $yellow-4;
    padding:40px;
    margin-top: 30px;
}
.enquiry-form .enquiry-title{
    font-size: $h4;
    color: $grey-5
}
.enquiry-form .form-control{
    background: transparent !important;
    border-bottom: 1px solid #B4B4B4 !important;
    border:none;
    border-radius: 0px !important;
    margin-top:30px;
}
.details-logos{
    margin-top: 30px;
}
.details-logos img{
    width: 80px;
    margin-right: 10px;
}
tr td{
    padding: 15px !important;
    font-size: $h6 !important;
    color:#4a4a4a !important;
    font-weight: 500;
}
tbody, td, tfoot, th, thead, tr{
    border-color: #D7D7D7 !important;
}
.product-details-title{
    font-size: 22px !important;
    color: $primary !important;
}
.product-info{
    margin-top:30px;
}
@media (max-width:900px) {
    .product-details-content {
        padding-left: 0px;
    }
    .enquiry-form {
        padding: 20px;
    }
}
@media (min-width:700px) {
    .mobile-details-display{
        display: none;
    }
    .desktop-details-display{
        display: block;
    }
}
@media (max-width:700px) {
    .mobile-details-display{
        display: block;
    }
    .desktop-details-display{
        display: none;
    }
}
