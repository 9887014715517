@import '../../css/variable.scss';
/** Certified Block **/
.certified-block{
    padding-bottom: 30px;
}
.certified-logos img{
    width: 80px;
    margin-right: 30px;
}
/** Custom Jewlery **/
.custom-jewlery{
    background-image: url('../../assets/Heritage_bg.jpg');
    background-size: cover;
}
.custom-image{
    margin-bottom: -75px;
text-align: end;
padding-left: 40px;
}
.custom-image img{
    width: 100%;
}
.custom-content-block h4{
    font-size: $h2;
    color: $terinary;
}
.custom-content-block h6{
    font-size: $h2;
    color: $grey-10;
}
.bg-section-custom{
    background: #ffffffc7;
    position: relative;
}
.custom-content-block{
    position: absolute;
    top:0px;
    bottom: 0px;
    display: flex;
    padding: 50px;
    align-items: center;
}
.custom-content-block p{
    font-size: $h6;
    color: $black;
}
/** Pick Favories **/
.favorites{
    padding-top:60px;
    padding-bottom: 60px;
}
.section-title{
    font-size: $h3;
    color: $black;
}
.jewlery-image{
    background: radial-gradient(50% 50% at 50% 50%, #521703 0%, rgba(47, 12, 0, 0.98) 100%);
    filter: drop-shadow(8.48713px 5.65809px 12.7307px rgba(0, 0, 0, 0.16));
    padding: 20px;
    transition: top ease 0.5s;
position: relative;
top: 0;
top: 0px;
}
.jewlery-title{
    font-size: $size24;
    color:$white;
    text-align: center;
    text-transform: uppercase;
}
.know-more-title{
    font-size: $size28;
    color:$white;
    text-align: center;
    padding: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: $grey-9;
}
.jewlery-image:hover {
    top: -20px;
}
.favorites::after{
    content: '';
    position: absolute;
    background-image: url('../../assets/bg2.svg');
    left: 0px;
    right: 0px;
    height: 180px;
    top: 38%;
}
.favorites{
    position: relative;
}
.favorites .item{
    margin-top:20px;
}
.location-title{
    font-size: $h4;
    color:#888888;
    text-align: center;
}
/** Heritage Jewlery **/
.heritage-jewlery {
    padding-top: 60px;
    padding-bottom: 60px;
}
.bg-jewlery-content {
    background: $grey-8;
    padding: 30px;
    display: flex;
    align-items: center;
}
.section-title {
    font-size: $h2;
    color: $black;
}
.jewlery-content h6 {
    font-size: $size24;
    display: flex;
    align-items: center;
}
.heritage-jewlery p {
    font-size: $size24;
    color: #434343;
}
.heritage-jewlery h6 span {
    background: $grey-10;
    height: 2px;
    width: 40%;
    display: flex;
    margin-top:10px;
    margin-left: 10px;
}
.jewlery-image1 img{
    background: $white;
box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
border-radius: 10px;
width: 100%;
}
.heritage-jewlery::after{
    content:'';
    position: absolute;
    background-image: url('../../assets/dot-shape.svg');
    height: 178px;
    width: 12%;
    bottom: 0px;
    background-size: cover;
    background-position: bottom;
    right: 0px;
    background-repeat: repeat;
}
.heritage-jewlery{
    position: relative;
}
/** FAQ **/
.faq{
    background: url('../../assets/Shape-FAQ.png');
padding: 30px;
  padding-bottom: 200px;
padding-top: 60px;
background-size: cover;
background-position: bottom;
position: relative;
// clip-path: polygon(20% 0%, 100% 0, 100% 20%, 100% 62%, 99% 62%, 48% 100%, 0 63%, 0 0);
}
.faq .accordion-body{
    font-size: $h5;
}
/* .faq::after{
    content: '';
    position: absolute;
    background-image: url('../../assets/Rectangle 231.svg');
    left: 0px;
right: 0px;
bottom: -160px;
background-size: contain;
z-index: 9999;
// background-color: red;
height: 300px;
background-repeat: no-repeat;
}
*/
.accordion-button {
    border-top: 1px solid $black !important;
    background: transparent !important;
    border-left: none !important;
    border-right: none !important;
    font-size: $size24;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 0px !important;
    color: $black;
    outline: none !important;
    box-shadow: none !important;
}
.accordion-button-last{
    border-bottom: 1px solid $black !important;
}

.accordion-item {
    border: none !important;
    background: transparent !important;
}
.accordion-body p{
    font-size:$h6;
    color: $black;
}
/** testimonails **/
.testimonials{
    padding-top:60px;
    padding-bottom: 100px;
    text-align: center;
}
.testimonials-title-block{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}
.testimonials-title-block img{
    width: 82px;
}
.pl-sm{
    padding-left: 10px;
}
.pr-sm{
    padding-right: 10px;
}
.line-shap img{
    width: 35%;
}
.testimonials p{
    font-size: $h5;
    color: $black;
}
.testimonial-title{
    font-size: $size28;
    color: $grey-4;
    margin-top:20px;
}
.carousel-indicators {
    margin-bottom: -40px;
}
.carousel-indicators [data-bs-target] {
  background-color: $light-grey;
  opacity: 1;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border-top: none !important;
  border-bottom: none !important;
}
.carousel-indicators .active {
background-color: $terinary !important;
}
/** About Jewleries **/
.about-jewleries{
    padding-top:60px;
    padding-bottom: 60px;
}
.about-jewleries .nav-tabs .nav-link{
    background: $yellow-4;
    font-size: $size24;
    border-radius: 0px;
    color: $black;
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.about-jewleries .nav-tabs .nav-item.show .nav-link,.about-jewleries .nav-tabs .nav-link.active{
    background: $light-yellow !important;
}
.about-jewleries .nav-tabs{
    border-bottom: none !important;
}
.tab-content{
    padding-top:20px;
}
.tab-image img{
    border-radius: 8px;
    padding-right: 10px;
}
.tab-content-block p{
    margin-bottom: 10px;
    font-size: $h5;
    color: $black;
}
/** Model Header **/
.modal-header{
    padding-top: 10px;
padding-bottom: 0px;
border-bottom: 0px;
}
.modal-body {
    padding:0px;
}
.making-video{
    margin-top:-26px;
}
.making-video img{
    min-height: 583.5px;
}
.custom-jtitle{
    font-size: $size28;
    color: $secondary !important;
}
.custom-jewlery-icon img{
    width: 52px;
}
.custom-jewlery-icon .journey-title{
    font-size: $size24;
    color:$black;
    margin-top:10px;
}
.custom-jewlery-icon p{
    font-size: $h6;
    color:$black;
    margin-top:10px;
}
.custom-jewlery-icon{
    margin-top:30px;
}
.jounery-footer-block{
    background: $terinary;
    padding:20px;
    margin-left: -25px;
}
.design-footer-title{
    font-size: $size24;
    color:$white;
}
.design-footer-title img{
    width: 28px;
}
@media (min-width: 992px){
.modal-lg, .modal-xl {
  max-width: 950px;
}
.design-form-modal .modal-lg, .design-form-modal .modal-xl{
max-width: 900px;
}
}
.process-images img{
    width: 91% !important;
}
.design-form-modal .modal-body{
    padding:20px;
}
.form-content p{
    font-size: $h5;
    color:$black;
}
.border-bottom{
    border-bottom: 1px solid $grey-4 !important;
}
.form-title{
    font-size: $size24;
    color:$black;
    font-weight: 400;
}
.text-caption{
    font-size: 13px;
    margin-top:10px;
}
.form-label{
    min-height: 20px;
}
.form-control{
    border: 1px solid #C8C8C8;
padding: 8px;
}
.btn-submit{
    background: #FAD7C9 !important;
    border-radius: 0px !important;
    border: none !important;
}
.btn-close{
    background-image: url('../../assets/entypo_cross.png');
    background-size: cover;
}
.modal-header .btn-close{
    width: 12px;
    height: 12px;
    opacity: 1;
}
@media (max-width:992px) {
    .certified-logos{
        display: flex;
        justify-content: space-between;
    }
    .certified-logos img {
        width: 55px;
        margin-right: 10px;
    }
    .custom-content-block h4 {
        font-size: 40px;
    }
    .custom-content-block h2{
        font-size: 22px;
    }
    .custom-image {
        margin-bottom: -20px;
        text-align: end;
        padding-left: 0px;
        width: 185px;
        margin-left: -30px;
        position: relative;
    }
    .section-title {
        font-size: 40px;
    }
    .favorites {
    padding-top: 40px;
    padding-bottom: 40px;
    }
    .favorites .owl-theme .owl-dots{
        display: none;
    }
    .favorites::after {
        height: 130px !important;
    }
    .testimonials {
        padding-top: 40px;
    }
    .padding-right-none{
        padding-right: 15px !important;
    }
    /* .faq::after {
       bottom: -160px;
        height: 200px;
    } */
    .nav-tabs{
        display: flex;
    }
    .nav-tabs .nav-item{
        width: 50%;
        display: flex;
    }
    .about-jewleries .nav-tabs .nav-link {
        font-size: 20px;
        padding-left: 20px !important;
        padding-right: 20px !important;
        width: 100%;
    }
    .tab-content-block{
        margin-top:30px;
    }
    .faq {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 100px;
    }
    .accordion-button {
        font-size: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 0px !important;
        line-height: initial;
    }
    .mobile-end{
        align-self: flex-end;
    }
    .favorite-space-adjust{
        margin-top:30px !important;
        margin-right: -30px !important;
    }
    .making-video{
        margin-top:-22px;
        width: 56% !important;
    }
    .making-video img {
        min-height: auto !important;
        margin-bottom: 10px !important;
    }
    .mobile-view{
        display: block;
    }
    .custom-jewlery-icon img{
        width:42px !important;
    }
    .jounery-footer-block {
        padding: 10px !important;
        margin-left: -10px !important;
    }
    .jounery-footer-block .text-end{
        text-align: center !important;
    }
    .form-content .section-title{
        font-size: 32px !important;
    }
    .form-content p {
        font-size: 16px !important;
    }
    .form-title {
        font-size: 18px !important;
    }
    .form-label {
        min-height: auto !important;
    }
    .form .mb-3{
        margin-bottom: 0px !important;
    }
    .mobile-hide{
        display: none !important;
    }
    .mobile-center{
        text-align: center !important;
    }
    .desktop-view{
        display: none;
    }
    .custom-content-block{
        position: relative;
        padding:10px;
    }
    .mobile-spacing{
        padding-left: 20px;
    }
    .custom-jewlery-icon .journey-title {
        font-size: 18px !important;
    }
    .journery-carousel .owl-dots{
        display: none;
    }
    .jewlery-title {
        font-size: 18px;
    }
    .jewlery-image{
        min-height: 200px;
        padding:10px !important;
    }
    .text-caption{
        margin-bottom: 20px;
    }
}
@media (min-width:992px) {
    .mobile-view{
        display: none;
    }
    .desktop-view{
        display: block;
    }
}
.book-appointment-icons p{
    font-size: $h5;
    color:$black;
}
.book-appointment-icons a{
    font-size: $h5;
    color:$black;
    text-decoration: none !important;
}