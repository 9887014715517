@import '../../css/variable.scss';
.banner-slider{
    padding-top:50px;
    padding-bottom: 50px;
    background-image: url('../../assets/dot-shape.svg');
    background-position: left center;
    background-repeat: no-repeat;
}
@media only screen and (min-width:1800px) and (max-width:2000px) {
    .banner-slider{
        padding-left: 17%;
    }
    .banner-block-content{
        width: 70%;
    }
    .custom-scroll-button{
        left:25% !important;
    }
}
@media only screen and (min-width:2000px) and (max-width:3000px) {
    .banner-slider{
        padding-left: 22%;
    }
    .banner-block-content{
        width: 73%;
    }
    .custom-scroll-button{
        left:24% !important;
    }
}
@media (min-width:3000px) {
    .banner-slider{
        padding-left: 30%;
    }
    .banner-block-content{
        width: 77%;
    }
    .custom-scroll-button{
        left:18% !important;
    }
}
@media only screen and (min-width:1600px) and (max-width:1800px) {
    .banner-slider{
        padding-left: 13%;
    }
    .banner-block-content{
        width: 67%;
    }
    .custom-scroll-button{
        left:28% !important;
    }
}
@media only screen and (min-width:1500px) and (max-width:1600px) {
    .banner-slider{
        padding-left: 10%;
    }
    .banner-block-content{
        width: 65%;
    }
    .custom-scroll-button{
        left:30% !important;
    }
}
@media only screen and (min-width:1300px) and (max-width:1500px) {
    .banner-slider{
        padding-left: 6%;
    }
    .banner-block-content{
        width: 63%;
    }
    .custom-scroll-button{
        left:30% !important;
    }
}
@media only screen and (min-width:1100px) and (max-width:1300px) {
    .banner-slider{
        padding-left: 8%;
    }
    .banner-block-content{
        width: 58%;
    }
}
@media only screen and (min-width:1100px) and (max-width:1200px) {
    .banner-block-content{
        width: 62% !important;
    }
    .banner-block{
        width: 400px !important;
    }
}
@media only screen and (min-width:900px) and (max-width:1100px) {
    .banner-slider{
        padding-left: 7%;
    }
    .banner-block-content{
        width: 57%;
    }
    .banner-block{
        width: 400px !important;
    }
}
.banner-slider .owl-dots{
    display: none;
}
@media (min-width:900px) {
    .banner-block{
        width: 480px;
    }
    .carousel{
        position: relative;
    }
    .banner-block-content{
        position: absolute;
        top: -2px;
        right: 0px;
        background: #ffffffc7;
        bottom: 0px;
        z-index: 999;
        padding: 80px;
        display: flex;
        align-items: center;
    }
}
@media (max-width:900px) {
    .banner-slider{
        padding-left: 10px;
padding-right: 10px;
    }
    .banner-block-content{
        margin-top:20px;
        padding-right: 20px;
    }
    .banner-block-content h1 {
        font-size: 45px !important;
        line-height: 45px !important;
    }
    .banner-block-content h4 {
        font-size: 24px !important;
    }
    .custom-scroll-button {
        left: auto !important;
        top:30% !important;
        right: 20px !important;
    } 
    .banner-slider {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
.banner-slider .custom-nav .owl-prev,.banner-slider .custom-nav .owl-next{
    display: none;
}
.custom-scroll-button{
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 33%;
}
.custom-scroll-button .btn img{
    width: 90%;
}
.btn-transparent{
    background: transparent !important;
    border:none !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.banner-block-content h4{
    font-size: $h4;
    color: $grey-10;
}
.banner-block-content h1{
    font-size: $h1;
    color:$terinary;
    line-height: inherit;
    text-transform: uppercase;
}