@import '../../css/variable.scss';
.footer-space {
    background: $grey-8;
}
.footer {
    padding-top: 60px;
    padding-bottom: 60px;
}
.footer-title{
    font-size: $size24;
    color:$black;
    text-transform: uppercase;
    font-weight: 600;
}
.footer-content ul li a {
    font-size: $h5;
    color: $black;
    text-decoration: none !important;
    font-weight: 500;
    width: 100%;
  }
  .footer-content ul {
    list-style: none !important;
    padding-left: 0px !important;
  }
  .footer-content ul li {
    padding-top: 10px;
  }
  .footer-content p{
    font-size: $h5;
    color: $black;
    text-decoration: none !important;
    font-weight: 500;
  }
  .footer-content h6 {
    font-size: $h5;
    font-weight: 600;
    color: $black;
    padding-top: 10px;
  }
  .copyright {
    padding-bottom: 20px;
  }
  .copyright p{
    font-size: $h6;
    color:$black;
  }
  .icons-display{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    list-style: none !important;
    justify-content: center;
  }
  .icons-display li img{
    width: auto;
    margin-right: 10px;
  }
  @media (max-width:992px) {
    .last-accordian .accordion-button{
      border-bottom: 1px solid #000000 !important;;
    }
  }