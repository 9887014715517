@import '../../css/variable.scss';
.navbar{
    background: $white !important;
    padding: 20px;
}
.nav-link{
    font-size: $h5;
    color:$black;
    padding-left: 20px !important;
padding-right: 20px !important;
}
.navbar-expand-lg .navbar-nav{
    width: 100%;
    position: relative;
    justify-content: center;
}
.navbar-nav::after{
    position: absolute;
content: "";
left: 20%;
right: 20%;
    background-image: url('../../assets/Line.png');
    height: 1px;
bottom: -10px;
background-size: 100%;
background-repeat: repeat-y;
}
@media (max-width:992px) {
    .mobile-menu{
        background: $black !important;
        padding-bottom: 10px !important;
    }
    .mobile-menu-w-100{
        width: 100%;
    }
    .navbar-toggler{
        background: $white !important;
    }
    .logo{
        padding-top:0px !important;
    }
    .offcanvas{
        width:60% !important;
        background: #FAD7C9 !important;
    }
    .nav-link{
        padding-top:10px !important;
        padding-bottom: 10px !important;
        border-bottom: 1px solid $black !important;
    }
    .social-icons{
        margin-top:30px;
        margin-bottom: 30px;
        justify-content: center;
    }
    .contact-info p {
        color: $black !important;
    }
    .contact-info{
        border-top:1px solid $black;
        padding-top:20px;
    }
    .contact-info p{
        text-align: center;
    }
    .offcanvas-header{
        justify-content: end;
    }
}
.dropdown-item{
    font-size: $h5;
    color: $black;
}
.dropdown:hover .dropdown-menu{
display: block !important;
}
.dropdown-menu{
    border:none !important;
}
@media (min-width:992px) {
    .dropdown-toggle::after {
        content: none !important;
    }
}
.dropdown-item{
    display: flex;
}
.dropdown-item span{
    width: 95%;
    align-self: center;
}
.dropdown-item strong{
    width: 5%;
    align-self: center;
}
.dropdown-item strong img{
    width:16px;
}
.dropdown-divider{
    border-top: 1px solid $black;
}
.dropdown-item.active, .dropdown-item:active{
    background: transparent !important;
    color: $black !important;
}