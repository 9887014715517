$primary:#D4B05E;
$secondary:#4E210D;
$terinary:#6C3725;
$light-yellow:#E8C6AB;
$grey-8:#FFFAF5;
$white:#FFFFFF;
$black:#000000;
$h1:75px;
$h2:50px;
$h3:42px;
$h4:36px;
$h5:18px;
$h6:16px;
$size24:24px;
$p:14px;
$yellow-2:#FDF7C7;
$grey:#C7C7C7;
$grey-10:#1D1B1D;
$grey-9:#300D02;
$size28:28px;
$grey-4:#380703;
$light-grey:#D0D0D0;
$yellow-4:#F2ECE7;
$grey-5:#606060;