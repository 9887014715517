@import '../css/variable.scss';
.thank-you{
    padding-top:60px;
    padding-bottom: 60px;
}
.thankyou-content-block{
    background-image: url('../assets/thankyou-bg.svg');
    padding-top: 40px;
    padding-bottom: 40px;
    background-size: cover;
}
.thankyou-content-block p{
    font-size: 20px;
    font-weight: 500;
    color: $white;
}
.thankyou-content-block .section-title{
    color: $white !important;
}
.thankyou-footer{
    padding-top:30px;
}
.justify-center{
    justify-content: center;
}
.thank-you .icon-image{
    margin-bottom: -10px;
    position: relative;
}
.thank-you .icon-image::before{
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    background-image: url('../assets/33886-check-okey-done.gif');
    background-size: cover;
    top: 24%;
left: 24.3%;
}